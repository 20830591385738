import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    //background: 'white',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    background:'transparent'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    background:'white'
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    //height: [22, null, 36],
    height: [35, null, 60],
    marginTop:'1.5rem',
    transition:'all 0.2s ease-in-out'
  })),
  logoScrolled: css(mq({
    'img' : css(mq({
      height: [22, null, 36],
      marginTop:'0px'
    }))
  }))
}