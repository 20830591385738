/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';
import { useState, useEffect } from 'react';

import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
import FsLightbox from 'fslightbox-react';
import { createPortal } from "react-dom";

function Portal({children}){
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function PhotoGallery(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'photoGallery', item.cssClass || item.anchorName || '');
    const _images = cmsUtils.payload(item, 'ImageUrl') ? cmsUtils.payload(item, 'ImageUrl').split("|") : [];
    const _thumbImages = [];
    //const _images = ['https://jaba.com.au/sites/jaba/media/images/multicolour-services-min.jpg'];
    const [toggler, setToggler] = useState(false);
    const [popupIndex, setPopupIndex] = useState(0);

    if(_images.length > 0){
        _images.forEach(el => {
            let _texts = el.split('/');
            let _text = el.replace(_texts[_texts.length-1], "@thumb/" + _texts[_texts.length-1]);
            _thumbImages.push(_text);
        });
        //console.log('222', _images2);
    }
    
    return(
        <div css={style.photoGallery} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {/* <FsLightbox
                toggler={ toggler }
                sources={_images}
            /> */}
            <Portal>
                <div style={{display:'none'}}>
                    PhotoGallery
                </div>
                <FsLightbox
                    toggler={ toggler }
                    sources={_images}
                    sourceIndex={popupIndex}
                />  
            </Portal>

            <div css={style.thumbnails}>
                {
                    _thumbImages.map((item, index) =>
                     <div css={style.thumbnail} className="thumbnail" key={index}>
                         <a href style={{cursor:'pointer'}} onClick = {(e) => {
                             //console.log("vds");
                             e.preventDefault();
                             e.stopPropagation();
                             setToggler(!toggler);
                             setPopupIndex(index);
                         }}>
                             {/* <img src={item} alt="image_1"/> */}
                             <div css={style.bg} style={{ backgroundImage: utils.css.bgUrlStyle(item) }}></div>
                         </a>
                    </div>)
                }
            </div>
        </div>
    );   
}