const holder = typeof window !== `undefined` ? window : {};
const storeName = '__j6_lazy_js'

export function loadJs(...scripts) {
  const promises = [];
  scripts.forEach((script) => promises.push(loadScript(script, holder)));
  return Promise.all(promises);
}

export function loadedJs(url) {
  if (!holder[storeName]) return false;
  return holder[storeName][url] && holder[storeName][url].loaded
}

function loadScript(url) {
  if (!holder[storeName]) holder[storeName] = {};

  return new Promise((resolve, reject) => {
    //resolve if already loaded
    if (holder[storeName][url] && holder[storeName][url].loaded) {
      resolve({ script: url, loaded: true, networkLoaded: false , status: 'Already Loaded' });
    }
    else {
      //load script
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        holder[storeName][url] = { loaded: true };
        resolve({ script: url, loaded: true, networkLoaded: true, status: 'Loaded' });
      };
      script.onerror = (error) => resolve({ script: url, loaded: false, status: 'Load failed' });
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  });
}