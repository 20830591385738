import React from 'react';
import * as formModelHelper from './formModelHelper';
import { getControl } from './controls';
import { getSiteControl } from '../../form';

export function renderControlDirect(control, handleChange, formOption) {
  if (control === null) return null;
  let Component = getSiteControl(control, formOption) || getControl(control, formOption);
  if (Component === null) return null;
  return <Component key={control.name} control={control} handleChange={handleChange} formOption={formOption} />;
}


export function renderControl(formRoot, nameOrIndex, handleChange, formOption) {
  //console.log(formRoot, name);
  const control = formModelHelper.findControl(nameOrIndex, formRoot);
  //console.log(control, formRoot, name, handleChange, formOption)
  if (control) {
    return renderControlDirect(control, handleChange, formOption);
  }
}

export default { renderControl, renderControlDirect }